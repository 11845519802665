import React from "react";
import { Link } from "gatsby";
import { Container, Grid, Button } from "@material-ui/core";

import Layout from "../components/theme/layout";
import SEO from "../components/theme/seo";

const NotFoundPage = () => (
  <Layout>
    <SEO title="404: Not found" />
    <Container className="screen-fill">
      <Grid item container>
        <Grid item md={12} className="md:text-center">
          <h1>NOT FOUND</h1>
          <p>
            This page has been{" "}
            <em className="teal">soaked from our archives.</em> Come back home.
          </p>
          <Link to="/" className="flex mt-10 md:justify-center">
            <Button variant="contained" color="primary">
              <span className="text-white">Return Home</span>
            </Button>
          </Link>
        </Grid>
      </Grid>
    </Container>
  </Layout>
);

export default NotFoundPage;
